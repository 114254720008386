import * as React from "react"
import { CallToAction } from "../callToAction"

export function Slant({ cta, hero }) {
  return (
    <div
      style={{ paddingTop: `${hero.heroPadding}` }}
      className="relative overflow-hidden kontakt"
    >
      <main className="mx-auto w-full pt-8 sm:pt-12 md:pt-16 lg:pt-20 pb-8 sm:pb-12 md:pb-16 lg:pb-20">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl text-white">
            {hero?.heading}
          </h1>
          <p className="text-base sm:text-lg sm:max-w-xl sm:mx-auto font-bold text-white">
            {hero?.subheading && hero.subheading[0]?.children[0]?.text}
          </p>
          <CallToAction {...cta} hero={hero} />
        </div>
      </main>
    </div>
  )
}

export default Slant
