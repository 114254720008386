import React from "react"
import { PageContext } from "../../context/pageProvider"
import CTAform from "../Form/CTAform"

const ContactForm = () => {
  const { meta } = React.useContext(PageContext)
  const { form: sanityForm } = meta
  return (
    <div className="contact_sidebar md:ml-7 md:mr-5 md:mb-6  w-full p-4 md:pt-9">
      <CTAform
        sanityForm={sanityForm.formFields}
        submitButtonId="button7"
        isContact
      />
    </div>
  )
}

export default ContactForm
